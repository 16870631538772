.App {
  text-align: left;
  font-family: 'Ubuntu', sans-serif;
}

.primary { color: #3C6A7E; }
.lime { color: #DDDB00; }
.yellow { color: #FCC000; }
.tomato { color: #E84E1B; }

.App-logo {
  height: 40vmin;
  pointer-events: none;
  margin: 20px 0;
}

.App-header {
  background-color: #f3f4f6; /* A light grey for pleasant appearance */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: #3C6A7E;
}

.App-link {
  color: #61dafb;
}

footer {
  padding: 20px 0;
  background-color: #f3f4f6; /* matching the header's background for symmetry */
  border-top: 1px solid #ccc; /* a subtle border for separation */
}

footer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

footer ul li a {
  text-decoration: none; /* removing the default link underline */
  color: #3C6A7E; /* using the primary color for consistency */
  transition: color 0.2s; /* smooth color transition on hover */
}

footer ul li a:hover {
  color: #E84E1B; /* changing color on hover to the tomato color for a subtle effect */
}

.cta-button {
  padding: 12px 20px;
  border: none;
  background-color: #3C6A7E; /* using the primary color */
  color: #fff; /* white text color */
  border-radius: 5px; /* a subtle roundness */
  font-size: 16px; /* making the text a bit larger */
  cursor: pointer; /* indicating it's clickable */
  margin-top: 20px; /* some space from the content above */
  transition: background-color 0.2s; /* smooth background color transition on hover */
}

.cta-button:hover {
  background-color: #3C6A7E; /* using the tomato color for hover effect */
}


.button-container {
  text-align: center; /* center the button horizontally */
  margin: 20px 0;     /* give some top and bottom space */
}

.big-button {
  display: inline-block;   /* to keep the link inline but allow padding */
  padding: 20px 40px;     /* make it bigger */
  font-size: 20px;        /* increase font size */
  border-radius: 10px;    /* a more pronounced roundness */
  text-decoration: none;  /* remove default link underline */
}

/* Keeping the hover styles consistent with other buttons */
.big-button.primary:hover {
  background-color: #3C6A7E;
  color: #fff;
}
